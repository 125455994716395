import { apolloClient } from "@/vue-apollo";
import getInstAdminDashboardSummary from  '@/graphql/Dashboards/getInstAdminDashboardSummary.gql';
import getSysAdminDashboardSummary from '@/graphql/Dashboards/getSysAdminDashboardSummary.gql';
import getSurveyorDashboardSummary from '@/graphql/Dashboards/getSurveyorDashboardSummary.gql';

// import Vue from "vue";


const surveyStore = {

	state: {
        instAdminDashboardSummary: [],
        sysAdminDashboardSummary: [],
        surveyorDashboardSummary: []
	},

	mutations: {
        setInstAdminDashboardSummary(state, data){
            state.instAdminDashboardSummary = data;
        },
        setSysAdminDashboardSummary(state, data){
            state.sysAdminDashboardSummary = data;
        },
        setSurveyorDashboardSummary(state,data){
            state.surveyorDashboardSummary = data;
        }
	
	},

	getters: {
        getInstAdminDashboardSummary(state){
            return state.instAdminDashboardSummary;
        },
        getSysAdminDashboardSummary(state){
            return state.sysAdminDashboardSummary;
        },
        getSurveyorDashboardSummary(state){
            return state.surveyorDashboardSummary;
        }
	},

	actions: {
        async fetchInstAdminDashboardSummary(context){
            apolloClient.query({
                fetchPolicy: "no-cache",
                query: getInstAdminDashboardSummary
            })
            .then((response)=>{
                context.commit('setInstAdminDashboardSummary',response.data.institutionAdminDashboard);
            })
        },
        async fetchSysAdminDashboardSummary(context){
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getSysAdminDashboardSummary
            })
            .then((response)=>{
                context.commit('setSysAdminDashboardSummary',response.data.systemAdminDashboard);
                // console.log('The data', response.data.systemAdminDashboard);
            })
        },
        async fetchSurveyorDashboardSummary(context){
            apolloClient.query({
                fetchPolicy: 'no-cache',
                query: getSurveyorDashboardSummary,
            })
            .then((response)=>{
                context
                // console.log("the data", response.data);
                context.commit('setSurveyorDashboardSummary', response.data.surveyorDashbaord);
            })
        }
		
	}
};

export default surveyStore;
