
const landingPageStore = {
    state: {
        drawer : false
    },
    mutations:{
        setDrawer(state){
            state.drawer = !state.drawer;
        }
    },
    getters:{
        getDrawer(state){
            return state.drawer;
        }
    }
}

export default landingPageStore;