import gql from "graphql-tag";


export const registerUser = gql`
    mutation register($email: String!, $username: String!, $password1: String!,$password2: String!){
        register(email: $email,username: $username,password1: $password1,password2: $password2){
            success
            errors
        }
    }
`;

export const tokenAuth = gql`
    mutation tokenAuth($email: String!, $password: String!){
        tokenAuth(email:$email, password:$password){
            token
            refreshToken
            success
            errors
            user{
                pk
                username
                firstName
                lastName
                email
            }
        }
    }
`

export const passwordchange = gql`
mutation passwordchange($oldPassword:String!, $newPassword1:String!, $newPassword2:String!){
    passwordChange(oldPassword:$oldPassword,newPassword1:$newPassword1,newPassword2:$newPassword2){
        token
        success
        errors
    }
  }
`;

export const passwordReset = gql`
    mutation passwordReset($token: String!,$newPassword1: String!,$newPassword2: String!){
        passwordReset(token:$token,newPassword1:$newPassword1,newPassword2:$newPassword2){
            success
            errors
        }
    }
`;


export const sendPasswordResetEmail = gql`
    mutation sendPasswordResetEmail($email: String!){
        sendPasswordResetEmail(email:$email){
            success
            errors
        }
    }

`;


export const verifyAccount = gql`
    mutation verifyAccount($token: String!){
        verifyAccount(token:$token){
            errors
            success
        }
    }
`;

export const revokeToken = gql `
    mutation revokeToken($refreshToken:String!) {
        revokeToken(refreshToken:$refreshToken){
            success
            errors
        }
    }
`;

export const myPermissions = gql`
    query myPermissions{
        myPermissions{
            permissionName
            permissionCode
            uniqueId
        }
    }
`;
