

const AuthRouter = 
  {
    path: "/auth",
    name: "Auth",
    component: () => import("@/modules/Auth/Auth.vue"),
    children: [
      {
        path: "register",
        name: "Register",
        component: () => import("@/modules/Auth/RegisterPage.vue"),
      },
      {
        path: "login",
        name: "Login",
        component: () => import("@/modules/Auth/LoginPage.vue"),
      },
      {
        path: "forgot_password",
        name: "Forgot Password",
        component: () => import('@/modules/Auth/PasswordResetEmail.vue'),
      },
      {
        path: "activate/:token",
        name: "ActivateAccount",
        component: () => import("@/modules/Auth/ActivateAccount.vue"),
      },
      {
        path: "activate/:userId/:token",
        name: "CustomActivateAccount",
        component: () => import("@/modules/Auth/CustomActivateAccount.vue"),
      },
      {
        path: "password-reset/:token",
        name: "ResetPasswordView",  
        component: () => import("@/modules/Auth/ResetPassword.vue")
      }
    ],
  }

export default AuthRouter;