import VueRouter from "vue-router";
import LandingPageView from "../views/LandingPageView.vue";
// import authRouter from "@/views/Auth/authRouter.js";

import AuthRouter from "@/modules/Auth/authRouter.js";
import MainLayoutRouter from '@/layouts/mainLayoutRouter.js';
// import _ from 'lodash'

import Vue from "vue";
Vue.use(VueRouter);


const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('@/views/home/HomePage.vue')
  },
  
  {
    path: "/landing",
    name: "Landing",
    component: LandingPageView,
  },
  
  {
    path: "/public-surveys",
    name: "PublicSurveys",
    component: () => import('@/modules/surveys & quizes/PublicSurveyQuizView.vue')
  },
  
  {
    path: "/response/:surveyId",
    name: "SurveyResponseView",
    component: () => import('@/modules/surveys & quizes/surveys/SurveyResponseView.vue'),
  },


  {
    ...AuthRouter,
  },

    ...MainLayoutRouter,

  {
    path: "/:catchAll(.)",
    name: "NOT_FOUND",
    component: LandingPageView
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


// router.beforeEach((to, from, next) => {
//   let isAuthenticated = localStorage.getItem('apollo-token') || null
//   if(isAuthenticated){
//     next();
//   }
//   else if((!isAuthenticated) && ["Login","Register"].includes(to.name)) {
//     next({name: 'Landing'});
//   }

// })

Vue.use(VueRouter)

export default router;