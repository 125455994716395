import { apolloClient } from "@/vue-apollo";
import Vue from "vue";
import addRole from "@/graphql/accessLevels/addRole.gql";
import addPermission from "@/graphql/accessLevels/addPermission.gql"
import addPermissionGroup from "@/graphql/accessLevels/addPermissionGroup.gql"
import getPermissionGroup from "@/graphql/accessLevels/getPermissionGroups.gql"
import assignPermissionsToRole from "@/graphql/accessLevels/assignPermissionsToRole.gql"

import institutionRoles from "@/graphql/accessLevels/getInstitutionRoles.gql"
import rolePermissions from "@/graphql/accessLevels/getPermissionsByRoleUniqueId.gql";

import editRole from "@/graphql/accessLevels/editRole.gql";
import deleteRole from "@/graphql/accessLevels/deleteRole.gql";
import assignRoleToUser from "@/graphql/accessLevels/assignRoleToUser.gql";
import getUsersAssignedToRole from "@/graphql/accessLevels/usersAssignedToRole.gql";

import unassignRoleFromUser from "@/graphql/accessLevels/unassignRoleFromUser.gql";


const AccessLevelStore = {
  state: {
    permissionGroups: [],
    allRoles: [],
    rolesInMyInstitution: [],
    rolePermissions: [],
    usersAssignedToRole: [],
  },
  mutations: {
    setRoles(state, data) {
      state.allRoles.push(data)
      
    },
    clearRoles(state){
      state.allRoles = []
    },
    setRolePermissions(state, data) {
      state.rolePermissions = data
    },
    deleteRole(state, data){

      state.allRoles.splice(
        state.allRoles.findIndex(({uniqueId}) => uniqueId === data.uniqueId),1
      )
     
    },
    setUsersAssignedToRole(state, data){
      state.usersAssignedToRole = data;
    },
    removeUserFromRole(state, data){
      state.usersAssignedToRole.splice(
        state.usersAssignedToRole.findIndex(({pk}) => pk === data.pk),1
      )
    }

  },

  getters: {
    getRoles(state) {
      return state.allRoles
    },

    getRolePermissions(state) {
      return state.rolePermissions
    },
    getUsersAssignedToRole(state){
      return state.usersAssignedToRole;
    }

  },

  actions: {
    async addRole(context, data) {
      await apolloClient
        .mutate({
          mutation: addRole,
          variables: {
            roleName: data.roleName
          },
        })
        .then((response) => {
          if (response.data.addRole.success == 200) {
              let roleData = {
                roleUniqueId:response.data.addRole.roleUniqueId,
                ...data
              }
              context.commit("setRoles", roleData);
            Vue.swal({
              icon: "success",
              text: "Role added successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    },
    async editRole(context, data) {

      await apolloClient.mutate({


        mutation: editRole,
        variables: { ...data }
      })
        .then((response) => {
          if (response.data.editRole.status == 200) {
            Vue.swal({
              icon: "success",
              text: "Role edited successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
    },

    async deleteRole(context, data) {
      await apolloClient.mutate({
        mutation: deleteRole,
        variables: { ...data }
      })
        .then((response) => {
          if (response.data.deleteRole.status == 200) {
						context.commit('deleteRole', { uniqueId: data.uniqueId })
            Vue.swal({
              icon: "success",
              text: "Role deleted successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          else {
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
    },

    async addPermission(context, data) {
      await apolloClient.mutate({
        mutation: addPermission,
        variables: {
          permissionName: data.permissionName,
          permissionCode: data.permissionCode,
          permissionDescription: data.permissionDescription
        },
      }).then((response) => {
        if (response.data.addPermission.status) {
          Vue.swal({
            icon: "success",
            text: "Permission added successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        else {
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: "Error occured!",
          });
        }
      })
    },

    async addPermissionGroup(context, data) {

      await apolloClient.mutate({

        mutation: addPermissionGroup,

        variables: {

          permissionGroup: {

            groupName: data.groupName,
            groupDescription: data.groupDescription

          }
        }

      })

        .then((response) => {

          if (response.data.addPermissionGroup.success == 'true') {

            Vue.swal({
              icon: "success",
              text: "Permission group added successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

          }

          else {

            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!",
              timer: 1500,
            });

          }

        })
    },

    async fetchPermissionGroups(context) {
      await apolloClient.query({
        query: getPermissionGroup,
      })
        .then((response) => {
          context.commit("setPermissionGroups", response.data.allPermissionGroups);
        })
    },

    async fetchRoleInMyInstitution(context) {
      context.commit("clearRoles");
      await apolloClient.query({
        fetchPolicy: "no-cache",
        query: institutionRoles
      })
        .then((response) => {
          response.data.getRolesInMyInstitution.forEach(role => {
            context.commit("setRoles",role);
          })
        })
    },
    async assignPermissionsToRole(context, data) {
      await apolloClient.mutate({
        mutation: assignPermissionsToRole,
        variables: {
          ...data
        }
      })
        .then((response) => { 
          if(response.data.assignPermissionsToRole.status == 200){
            Vue.swal({
              icon: "success",
              text: "Permissions assigned successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
          else{
            Vue.swal({
              icon: "error",
              title: "Oops...",
              text: "Error occured!",
              timer: 1500,
            });
          }
          context.dispatch("fetchRolePermissions", data.roleUniqueId)
        })
    },
    async fetchRolePermissions(context, uniqueId) {
      apolloClient.query({
        query: rolePermissions,
        variables: { uniqueId }
      })
        .then((response) => {
          context.commit("setRolePermissions", response.data.getPermissionsByRoleUniqueId);
        })
    },
    async assignRoleToUser(context, user){
      apolloClient.mutate({
        mutation: assignRoleToUser,
        variables: {...user}
      })
      .then((response)=>{
        if(response.data.assignRoleToUser.status == 200){
          Vue.swal({
            icon: "success",
            text:response.data.assignRoleToUser.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        else{
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: response.data.assignRoleToUser.message,
            showConfirmButton: false,
            timer: 1500
          });
        }
      })
    },
    async fetchUsersAssignedToRole(context, data){
      apolloClient.query({
        query: getUsersAssignedToRole,
        fetchPolicy: 'no-cache',
        variables: {...data}
      })
      .then((response)=>{
        context.commit('setUsersAssignedToRole',response.data.getUsersByRoleUniqueId)
      })
    },
    async unassignRole(context, data){
      apolloClient.mutate({
        mutation: unassignRoleFromUser,
        variables:{...data}
      })
      .then((response)=>{
        if(response.data.unassignUserFromRole.status == 200){
          context.commit('removeUserFromRole',{data: data});
          Vue.swal({
            icon: "success",
            text:response.data.unassignUserFromRole.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        else{
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: response.data.unassignUserFromRole.message,
            showConfirmButton: false,
            timer: 1500
          });
        }

      })
    }
  },
};
export default AccessLevelStore;
