import { apolloClient } from "@/vue-apollo";
import Vue from "vue";
import addInstitution from "@/graphql/manageinstitutions/addInstitution.gql";
import allInstitutions from "@/graphql/manageinstitutions/allInstitutions.gql";
import deleteInstitution from "@/graphql/manageinstitutions/deleteInstitution.gql";
import getInstitution from "@/graphql/manageinstitutions/getInstitution.gql";
import editInstitution from "@/graphql/manageinstitutions/editInstitution.gql";
// import institutionsNumber from "@/graphql/manageinstitutions/totalInstitutions.gql";
import institutionManagementAnalysis from "@/graphql/manageinstitutions/getInstitutionalAnalysis.gql";

const ManageInstitutionsStrore = {

    state: {

        Allinstitutions: [],
        institution:{},
        institutionsNumber:""

    },

    mutations: {

        setInstitutions(state,data){
            state.Allinstitutions.push(data);
        },
        clearInstitutions(state){
            state.Allinstitutions = [];
        },

        setInstitution(state,institution){

            state.institution = institution;
        },
        setInstitutionsNumber(state,data){
            state.institutionsNumber = data
        }

    },
    getters: {
        getAllInstitutions(state) {
            return state.Allinstitutions;
        },
        getInstitution(state){
            return state.institution
        },
        getInstitutionsNumber(state){
            return state.institutionsNumber
        }
    },


    actions: {
        async addInstitution(context, data) {
            await apolloClient.mutate({
                mutation: addInstitution,
                variables: data
            })
            .then((response) => {
                if (response.data.newInstitution.statusCode == 200) {
                    let instData = {
                        institutionUniqueId: response.data.newInstitution.institutionUniqueId, 
                        // institutionLogo: response.data.newInstitution.institutionLogo,
                        ...data
                    }
                    context.commit("setInstitutions",instData);
                    Vue.swal({
                        icon: "success",
                        text: "Institution added successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                    });

                }
                else {

                    Vue.swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Error occured!",
                    });

                }
            })
        },

        async fetchAllInstitutions(context) {
            context.commit('clearInstitutions');
            await apolloClient.query({
				fetchPolicy: "no-cache",
                query: allInstitutions,

            })
            
            .then((response)=>{
                // console.log("The institutions are", response.data.getAllInstitutions);
                // context.commit("setAllInstitutions",response.data.getAllInstitutions);
                response.data.getAllInstitutions.forEach(institution => {
                    context.commit("setInstitutions", institution);
                })
            })
        },
        async deleteInstitution(context, uniqueId) {
          await  apolloClient.mutate({
                mutation: deleteInstitution,
                variables: {
                    uniqueId: uniqueId
                }
            })
            .then((response)=>{
                if(response.data.deleteInstitution.statusCode == 200) {
                    Vue.swal({
                        icon: "success",
                        text: "Institution deleted successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    context.dispatch("fetchAllInstitutions");
                }
                else{
                    Vue.swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Error occured!",
                        showConfirmButton: false,
                        timer: 1500
                    });

                }
            })

        },

        async fetchInstitution(context, uniqueId){
            await apolloClient.query({
                query: getInstitution,
                variables: {
                    uuid: uniqueId
                }
            })
            .then((response)=>{
                context.commit("setInstitution", response.data.getInstitutionByUuid);
            })
        },

        async editInstitution(context, data){
            await apolloClient.mutate({
                mutation: editInstitution,
                variables: {...data}
            })
            .then((response)=>{
                if(response.data.updateInstitution.statusCode == 200){
                    Vue.swal({
                        icon: "success",
                        text: "Institution edited successfully!",
                        showConfirmButton: false,
                        timer: 1500,
                    });

                context.dispatch("fetchAllInstitutions");

                }
                else{
                    Vue.swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Error occured!",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                
            })
        },
        async fetchInstitutionsNumber(context){
            await apolloClient.query({
                query: institutionManagementAnalysis
            })
            .then((response)=>{
                
                context.commit("setInstitutionsNumber", response.data.getInstitutionManagementAnalysis.totalNumberInstitution)
                
            })
        }
    },



}

export default ManageInstitutionsStrore;