import "regenerator-runtime";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from 'vue-apexcharts';
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/index.css";
import "./assets/style.css";
import "@/plugins/notification.service.js";
import "echarts";
import "@/plugins/echarts";
import VueCarousel from 'vue-carousel';
import SequentialEntrance from 'vue-sequential-entrance'
import 'vue-sequential-entrance/vue-sequential-entrance.css'

import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import vue2Dropzone from "vue2-dropzone";

Vue.config.productionTip = false;

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'http://localhost:8081/toPrint.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: "window.document.title", // override the window title
}

Vue.use(VueHtmlToPaper, options);
Vue.use(VueSweetalert2);
Vue.use(VueCarousel);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })

Vue.component("vue2Dropzone", vue2Dropzone)
Vue.use(SequentialEntrance); //pdf

Vue.filter("formatDate", function (value) {
  if (value) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(value).toLocaleDateString('en-GB', options)
  }
});


Vue.mixin({
    computed: {
        hasAccess:() => (permission) => {
          
        const permissions = store.getters.getUserPermissions
    
        let p = permissions.find((_permission) => _permission?.permissionCode === permission)
        return p ? true : false
        },    
    },
})

new Vue({
  apolloProvider: createProvider(),
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");