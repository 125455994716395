import { apolloClient } from "@/vue-apollo";
import systemSummary from "@/graphql/systemManagement/systemSummary.gql";

const systemManagementStore = {

    state:{
        institutionNumber:"",
        packageNumber:"",
        userNumber: "",
        permissionGroups:[],
        surveyNumber:"", // The total number of all  surveys the entire system
        quizNumber: "" // The total number of all  quizes the entire system
    },

    mutations:{
        setInstitutionNumber(state, data){
            state.institutionNumber = data;
        },
        setPackageNumber(state, data){
            state.packageNumber = data;
        },
        setUserNumber(state, data){
            state.userNumber = data;
        },
        setPermissionGroups(state, data){
            state.permissionGroups = data;
        },
        setSurveyNumber(state, data) {
            state.surveyNumber = data;
        },
        setQuizNumber(state, data){
            state.quizNumber = data;
        }


    },


    actions:{
        async fetchSystemSummary(context){
            await apolloClient.query({
                query: systemSummary
            })
            .then((response) => {

                context.commit("setInstitutionNumber", response.data.getSystemAdminAnalysis.totalInstitution)
                context.commit("setPackageNumber", response.data.getSystemAdminAnalysis.totalPackages)
                context.commit("setUserNumber", response.data.getSystemAdminAnalysis.totalUsers)
                context.commit("setSurveyNumber", response.data.getSystemAdminAnalysis.totalSurvey)
                context.commit("setQuizNumber", response.data.getSystemAdminAnalysis.totalQuiz)
            })
        },

// 

    },
    getters:{
        getInstitutionNumber(state){
            // console.log("the data reached here",state.institutionNumber );
            return state.institutionNumber
        },
        getPackageNumber(state){
            return state.packageNumber 
        },
        getUserNumber(state){
            return state.userNumber 
        },
        getPermissionGroups(state){
            return state.permissionGroups
        },
        getSurveyNumber(state) {
            return state.surveyNumber
        },
        getQuizNumber(state){
            return state.quizNumber
        }
        
    },
//     getSystemAdminAnalysis:
// totalInstitution: 5
// totalPackages: 1
// totalSurvey: 3
// totalUsers: 31


}
export default systemManagementStore;