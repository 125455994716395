

export default [
  {
      path: "/lite",
      name: "Lite",
      component: () => import('@/layouts/MainLayout.vue'),
      children: [

        // DASHBOARD ROUTES

        {
            path: "/system-admin-dashboard",
            name: "system-admin-dashboard",
            component: () => import('@/modules/Dashboards/SysAdminDashboard.vue'),
            beforeEnter : checkAuth
        },

        {
            path: "/institution-admin-dashboard",
            name: "institution-admin-dashboard",
            component: () => import('@/modules/Dashboards/InstAdminDashboard.vue'),
            beforeEnter : checkAuth
        },
        {
            path: "/surveyor-dashboard",
            name: "surveyor-dashboard",
            component: () => import('@/modules/Dashboards/UserDashboard.vue'),
            beforeEnter : checkAuth
        },

        // SURVEYS AND QUIZ ROUTES

        {
          path: "/survey-list",
          name: "survey-list",
          component: () => import('@/modules/surveys & quizes/surveys/surveysList.vue'),
          beforeEnter : checkAuth
        },
        {
            path: "/quiz-list",
            name: "Quiz-list",
            component: () => import('@/modules/surveys & quizes/quizes/QuizesList.vue'),
            beforeEnter : checkAuth
        },
        {
            path: "/preview_quiz_answers/:quizId",
            name: "PreviewQuizAnswers",
            component: () => import('@/modules/surveys & quizes/quizes/PreviewQuizAnswers.vue')
        },

        {
            path: "/creator/:surveyId",
            name: "SurveyCreator",
            component: () => import('@/modules/surveys & quizes/surveys/SurveyCreator.vue'),
            beforeEnter : checkAuth
        },

        {
            path: "/analytics/:surveyId",
            name: "SurveyAnalytics",
            component: () => import('@/modules/surveys & quizes/surveys/SurveyAnalytics.vue'),
            beforeEnter : checkAuth
        },
        {
            path: '/printAnalysis/:surveyId',
            name: 'PrintSurveyAnalysis',
            component: () => import('@/modules/surveys & quizes/surveys/printAnalysis.vue'),
            beforeEnter: checkAuth
        },
        {
            path: "/import_survey",
            name: "ImportSurveyView",
            component: () => import('@/modules/surveys & quizes/surveys/ImportSurveyView.vue'),
            beforeEnter : checkAuth,
        },
        {
            path: "/preview-answers/:surveyId",
            name: "PreviewSurveyAnswers",
            component: () => import('@/modules/surveys & quizes/surveys/PreviewSurveyAnswers.vue')
        },
        {
            path: "/respondent-view/:surveyId",
            name: "RespondentsTable",
            component: () => import('@/modules/surveys & quizes/surveys/RespondentsTable.vue'),
            beforeEnter : checkAuth
        },  
        {
            path: "/export/:surveyId",
            name: "SurveyExporter",
            component: () => import('@/modules/surveys & quizes/surveys/SurveyExporter.vue'),
            beforeEnter : checkAuth
        },
            // End of the survey & quiz routes
        {
            path: "/survey-categories",
            name: "SurveyCategoryManagement",
            component: () => import('@/modules/surveys & quizes/SurveyCategoryManagement.vue'),
            beforeEnter : checkAuth
        },


        // MANAGE INSTITUTION ROUTES

        {
            path: "/institution-management",
            name: "ManageInstitutions",
            component: () => import('@/modules/InstitutionManagement/InstitutionManagement.vue'),
            beforeEnter : checkAuth
        },

        // MANAGE USERS ROUTER

        {
            path: "/manage-users",
            name: "ManageUsers",
            component: () => import('@/modules/UserManagement/ManageUsers.vue'),
            beforeEnter : checkAuth
        },

        // ACCESS LEVELS ROUTER

        {
            path: "/access-levels",
            name: "AccessLevel",
            component: () => import('@/modules/AccessLevels/AccessLevel.vue'),
            beforeEnter : checkAuth
        },
        {
            path:'/users-assigned-to-role/:roleUniqueId',
            name: 'UsersAssignedToRole',
            component: () => import('@/modules/AccessLevels/UsersAssignedToRole.vue'),
            beforeEnter : checkAuth
        },
        {
            path:"/permissions/:roleId",
            name: "permissionsView",
            component: () => import('@/modules/AccessLevels/Permissions.vue'),
            beforeEnter: checkAuth
        }
      ]
  }
]

function checkAuth(to, from, next) {
    let isAuthenticated = localStorage.getItem("apollo-token");
    if (isAuthenticated){
      next();
    } 
    else{
      next("/auth/login");
    }   
  }