import { apolloClient } from "@/vue-apollo";
import router from "../../router";
import customActivateAccount from '@/graphql/Auth-graphql/customActivateAccount.gql';
import defaultUserRole from '@/graphql/accessLevels/getDefaultUserRole.gql';

import { registerUser, tokenAuth, passwordChange, passwordReset, sendPasswordResetEmail, revokeToken, verifyAccount, myPermissions } from "@/graphql/Auth-graphql/auth.js";

import { notifyError, notifySuccess}  from "@/plugins/notification.service.js"

export const hasPermission = (permissions, permission) => {
  let p = permissions.find((_permission) => _permission.permissionCode === permission)
  return p ? true : false
}

const AuthStore = {
  state: {
    loggedUser: {},
    userPermissions: [],
    loginLoading: false,
    defaultUserRole:""
  },
  mutations: {
    setLoggedUser(state, user) {
      state.loggedUser = user;
    },
    SET_LOGIN_LOADING(state, loading) {
      state.loginLoading = loading;
    },
    REMOVE_LOGGED_USER(state) {
      state.user = {}
    },
    setUserPermissions(state, permissions) {
      state.userPermissions = permissions
    },
    setDefaultUserRole(state, data){
      state.defaultUserRole = data;
    }
  },
  getters: {
    getLoggedUser: (state) => {
      return state.loggedUser;
    },
    getLoginLoading: (state) => {
      return state.loginLoading;
    },
    getUserPermissions(state) {
      return state.userPermissions
    },
    getDefaultUserRole(state){
      return state.defaultUserRole;
    }
  },

  actions: {
    async userRegister(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient.mutate({
        fetchPolicy: "no-cache",
        mutation: registerUser,
        variables: {...data}
      })
      .then((response) => {

        let { success, errors } = response.data.register;

        if (success) {
          router.push({ name: "Login"})
          notifySuccess("User registered successfully")
        }
        else {
          notifyError(Object.entries(errors).map(([, value], index) => (value[index]?.message)).join("\n").toString() || "Registration failed")
        }
      })
      .finally(() => context.commit("setLoadingState", false))
    },

    async userLogin(context, data) {
      context.commit("setLoadingState", true);
      return await apolloClient.mutate({
        mutation: tokenAuth,
        variables: {...data}
      })
      .then((response) => {

        let { success, errors } = response?.data?.tokenAuth;
        if(success){

          context.dispatch("fetchUserPermissions");
          context.dispatch('fetchUserDefaultRole');
          localStorage.setItem("apollo-token", response.data.tokenAuth.token);
          localStorage.setItem('refreshToken', response.data.tokenAuth.refreshToken);
          context.commit("setLoggedUser", response.data.tokenAuth.user);
          
          notifySuccess("Login successfully!")

        } else {
          notifyError(Object.entries(errors).map(([, value], index) => (value[index]?.message)).join("\n").toString() || "Login failed")
        }
      }).catch((error) => {
        if (error.networkError.statusCode == 403) {
          notifyError("Too many attempts, account blocked! \n Contact your administrator")
        }
        
      })
      .finally(() => context.commit("setLoadingState", false))
    },

    async activateAccount(context, data) {
      context.commit("setLoadingState", true);
      apolloClient.mutate({
        mutation: verifyAccount,
        variables: { ...data }
      })
        .then((response) => {

          let { success, errors } = response.data.verifyAccount

          if (success) {
            notifySuccess("Account activated successfully!")
          }
          else {
            notifyError(Object.entries(errors).map(([, value], index) => (value[index]?.message)).join("\n").toString() || "Account activation failed")
          }
        })
        .finally(() => context.commit("setLoadingState", false))
    },

    async customActivateAccount(context, data) {
      context.commit("setLoadingState", true);
      apolloClient.mutate({
        mutation: customActivateAccount,
        variables: { ...data }
      })
      .then((response) => {

        let { success, errors } = response.data.activateAccount;

        if (success) {
          notifySuccess("Account activated successfully!")
        }
        else {
          notifyError(Object.entries(errors).map(([, value], index) => (value[index]?.message)).join("\n").toString() || "Account activation failed")
        }
      })
      .finally(() => context.commit("setLoadingState", false))
    },

    async fetchUserPermissions(context) {
      await apolloClient.query({
        query: myPermissions
      })
        .then((response) => {
          if (response.data.myPermissions) {
            context.commit("setUserPermissions", response.data.myPermissions);
          }
          else {
            notifyError("Error occured while fetching user permissions")
          }

        }).catch((err) => {
           notifyError(err)
        })
    },

    async resetPassword(context, data) {
      context.commit("setLoadingState", true);
      await apolloClient.mutate({
        mutation: passwordReset,
        variables: data
      })
        .then((response) => {

          let { success, errors } = response.data.passwordReset;
          
          if (success) {
            notifySuccess("Password changed successfully!")
          }
          else {
            notifyError(Object.entries(errors).map(([, value], index) => (value[index]?.message)).join("\n").toString() || "Error occurred while changing password")
          }

        })
        .finally(() => context.commit("setLoadingState", false))
    },

    async resetPasswordEmail(context, data) {
      context.commit("setLoadingState", true);
      await apolloClient.mutate({
        mutation: sendPasswordResetEmail,
        variables: data
      })
        .then((response) => {

          let { success, errors } = response.data.sendPasswordResetEmail;
          if (success) {
            notifySuccess("Email sent successfully!")
          }
          else {
            notifyError(Object.entries(errors).map(([, value], index) => (value[index]?.message)).join("\n").toString() || "Reset password email is not sent")
          }

        })
        .finally(() => context.commit("setLoadingState", false))
    },

    async changePassword(context, data) {
      context.commit("setLoadingState", true);
      apolloClient.mutate({
        mutation: passwordChange,
        variables: { ...data }
      })
        .then((response) => {

          let { success, errors } = response.data.passwordChange

          if (success) {
            notifySuccess("Password changed successfully!")
          }
          else {
            notifyError(Object.entries(errors).map(([, value], index) => (value[index]?.message)).join("\n").toString() || "Reset password email is not sent")
          }
        })
        .finally(() => context.commit("setLoadingState", false))
    },

    async revokeToken(context, data) {
      apolloClient.mutate({
        mutation: revokeToken,
        variables: {
          refreshToken: data.refreshToken
        }
      })
        .then((response) => {
          response
          localStorage.removeItem("refreshToken");
        })
    },
    async fetchUserDefaultRole(context){
      apolloClient.query({
        query: defaultUserRole
      })
      .then((response)=>{
        context.commit('setDefaultUserRole',response.data.userRole.roleId.roleName);
        if(response.data.userRole.roleId.roleName == 'Institution Admin'){
          router.push('/institution-admin-dashboard');
        }
        else if(response.data.userRole.roleId.roleName == 'Normal User'){
          router.push('/surveyor-dashboard');
        }
        else if(response.data.userRole.roleId.roleName == 'System Admin'){
          router.push('/system-admin-dashboard');
        }
      })
    }
  },

};
export default AuthStore;
