import gql from "graphql-tag";

export const createCategory = gql`
    mutation createCategory($categoryName:String){
        addSurveyCategory(categoryName:$categoryName){
            status
            categoryUniqueId
        }
    }
`;

export const copySurvey = gql`
    mutation copySurvey($surveyAccess: String! $surveyEnddate: String! $surveyStartdate: String! $surveyUniqueId: String!){
        copySurvey(surveyAccess:$surveyAccess, surveyUniqueId:$surveyUniqueId, surveyStartdate:$surveyStartdate,  surveyEnddate: $surveyEnddate){
            status
            message
            surveyNumber
        }
    }
`;


export const createNewSurvey = gql`
    mutation createNewSurvey($categoryUniqueId: String, $respondents: [SurveyRespondents], $access: String, $surveyEndDate: String, $surveyStartDate: String, $surveyTitle: String, $surveyType: String){
        createNewSurvey(categoryUniqueId: $categoryUniqueId, surveyTitle: $surveyTitle, surveyAccess: $access, surveyEnddate: $surveyEndDate, surveyStartdate: $surveyStartDate, respondents: $respondents, surveyType: $surveyType){
            statusCode
            surveyNumber
            surveyUniqueId
        }
    }
`;

export const deleteSurvey = gql`
    mutation deleteSurvey($surveyUniqueId: String!){
        deleteSurvey(surveyUniqueId:$surveyUniqueId){
        status
        message
        }
    }
`;

export const deleteSurveyCategory = gql`
mutation deleteSurveyCategory($categoryUid: String){
    deleteSurveyCategory(categoryUid: $categoryUid) {
        status
        message
    }
}
`;


export const institutionalAdminMonthlyChart = gql`
query institutionalAdminMonthlyhart{
    getInstitutionAdminMonthlyChart{
        month
        year
        totalSurveys
        publicSurveyCounts
        privateSurveyCounts
        inactiveSurveyCounts
    }
  }
`;


export const getSurveys = gql`
    query loadUserSurveys{
        loadUserSurveys{
            surveyUniqueId
            surveyTitle
            surveyPayload
            surveyInstitution
            surveyCreatedDate
            surveyNumber
            surveyCreator
            surveyStartDate
            surveyEndDate
            access
        }
    }
`;

export const publicSurveys = gql`
    query publicSurveys{
        loadPublicSurveysAll{
            surveyUniqueId
            surveyNumber
            surveyPayload
            surveyTitle
        }
    }
`;



export const surveyPayload = gql`
    query loadPayload($surveyUniqueId: String){
        loadPayload(surveyUniqueId:$surveyUniqueId){
            payload
        }
    }
`;

export const updateSurveyPayload = gql`
    mutation updateSurveyPayload($updatedSurvey: UpdateSurveyPayloadObject!){
        updateSurveyPayload(updatedSurvey:$updatedSurvey){
            updated
        }
    }
`;

export const saveSurveyResponse = gql`
    mutation saveSurveyResponse($surveyAnswers: String!,$surveyPayloadUrl: String!) {
        saveSurveyResponse(surveyAnswers: $surveyAnswers, surveyPayloadUrl:$surveyPayloadUrl) {
            status
            message
        }
    }
`;

export const surveyAnswers = gql`
    query surveyAnswers($surveyUniqueId:String!){
        loadSurveyAnswers(surveyUniqueId:$surveyUniqueId){
            status
            answers
    }
}
`;

export const privateSurvey = gql`
    query getPrivateSurvey($surveyNumber: String!) {
        getPrivateSurvey(surveyNumber: $surveyNumber) {
            payload
            status
            uniqueId
        }
    }
`;


export const surveysByStatus = gql`
    query loadUserSurveys($surveyStatus: String, $surveyType: String){
        loadUserSurveys(surveyStatus: $surveyStatus,surveyType:$surveyType ){
            surveyUniqueId
            surveyTitle
            surveyPayload
            surveyCreatedDate
            surveyNumber
            surveyCreator
            surveyStartDate
            surveyEndDate
            access
        }
    }
`;


export const updateSurveyStatus = gql`
    mutation updateSurveyStatus ($surveyStatus: String, $uniqueId: String){
        updateSurveyStatus(surveyStatus: $surveyStatus, uniqueId: $uniqueId) {
            status
            message
        }
    }
`;

export const surveyAnalytics = gql`
    query getSurveyManagementAnalysis($surveyType: String) {
        getSurveyManagementAnalysis(surveyType: $surveyType) {
            totalSurveys
            activeSurveys
            inactiveSurveys
            draftSurveys
            totalSurveys
            totalQuizToRespond
            totalSurveysToRespond
        }
    }
`;

export const loadPrivateSurveyToRespond = gql`
    query loadPrivateSurveyToRespond($surveyType:String){
        loadPrivateSurveyToRespond(surveyType:$surveyType){
            surveyTitle
            surveyNumber
            surveyStatus
            surveyEndDate
            surveyUniqueId
            surveyCategory
            surveyStartDate
            surveyInstitution
            surveyCreatedDate
            surveyCreator
            surveyType
        }
    }
`;

export const loadSurveysByCategory = gql`
query getallpublicsurveys($categoryUid: String){
    loadSurveysByCategory(categoryUid:$categoryUid){
      surveyTitle,
      surveyNumber,
      surveyCreatedDate
    }
  }
`;

export const fetchSurveyByUid = gql`
    query loadSurveyByUid($surveyUniqueId: String){
        loadSurveyByUid(surveyUniqueId: $surveyUniqueId) {
            surveyTitle
            surveyUniqueId
            surveyStatus
            surveyInstitution
            surveyCategory
            surveyStartDate
            surveyEndDate
            surveyType
        }
    }
`;

  
export const addSurveyCategory = gql`
    mutation createCategory($categoryName:String){
        addSurveyCategory(categoryName:$categoryName){
        status
        categoryUniqueId
        }
    }
`;

export const surveyAnalysis = gql`
    query getSurveyAnalysis($surveyUniqueId: String){
        getSurveyAnalysis(surveyUniqueId: $surveyUniqueId) {
            questionName
            figPath
            matrixFigPath {
                figPath
            }
        }
    }
`;


export const surveyAnalysisPdf = gql`
    query getSurveyAnalysisPdf($surveyUniqueId: String){
        getSurveyAnalysisPdf(surveyUniqueId: $surveyUniqueId){
            surveyTitle
            surveyAnalysisPdf
        }
    }
`;


export const getSurveyRespondentsAnalysis = gql`
    query getSurveyRespondentsAnalysis {
        getSurveyRespondentsAnalysis {
            totalSurveys
            totalQuiz
        }
    }
`;

export const OverallSurveyAnswers = gql`
    query getSurveyAnswers($surveyUniqueId:String){
        getSurveyAnswers(surveyUniqueId: $surveyUniqueId) {
        surveyJson
            answers {
                answerName
                answerJson
            }
        }
    }
`;

export const monthlyInstAdminVisualizations = gql`
    query institutionalAdminMonthlyhart{
        getInstitutionAdminMonthlyChart{
            month
            year
            totalSurveys
            publicSurveyCounts
            privateSurveyCounts
            inactiveSurveyCounts
        }
    }
`;

export const monthlySurveyorVisualization = gql`
    query SurveyorMonthlyChart{
        getSurveyorMonthlyChart{
            month
            year
            totalSurveys
            publicSurveyCounts
            privateSurveyCounts
        }
    }
`;

export const monthlySysAdminVisualizations = gql`
    query systemAdminMonthlyChart{
        getAdminChart{
            month
            year
            totalSurveys
            publicSurveyCounts
            privateSurveyCounts
            inactiveSurveyCounts
        }
    }
`;