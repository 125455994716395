<template>
  <div class="relative h-full">
    <div class="w-full h-full relative">
      <div class="w-full h-full absolute background"></div>
      <img class="absolute top-0 m-5" src="@/assets/images/tz.png" alt="Nembo ya taifa" width="100px" />
      <h1 class="font-bold text-3xl top-0 absolute right-0 mr-5 mt-14 text-indigo-700">
        eDodoso
      </h1>
      <div class="top-0 right-0 w-1/3 h-full flex flex-col justify-center absolute">
        <div class="rounded-l-md border-l-4 border-indigo-600 shadow-black shadow-sm">
          <div class="flex flex-row items-center bg-indigo-600 p-2">
            <v-btn icon color="white">
              <v-icon>
                <!-- mdi-chevron-right -->
              </v-icon>
            </v-btn>
            <span class="font-bold text-white">
              <!-- Actions -->
            </span>
          </div>
          <div class="p-4" style="background-color: rgba(255,255,255,1)">
            <div class="flex flex-row justify-end">
              <v-btn class="mx-1 white--text" style="text-transform: unset" color="primary" to="/auth/register">
                <span>
                  <v-icon>mdi-account-plus-outline</v-icon>
                </span>
                <span>Register</span>
              </v-btn>

              <v-btn class="mx-1 white--text" style="text-transform: unset" color="primary" to="/auth/login">
                <span>
                  <v-icon>mdi-login</v-icon>
                </span>
                <span>Login</span>
              </v-btn>
            </div>

            <hr class="mt-4" />
            <div class="text-blue-500 my-1 text-sm font-bold"></div>
            <div>
              <form>
                <div class="flex flex-row items-center">
                  <v-text-field hide-details dense outlined placeholder="Enter Survey number" background-color="rgba(255,255,255,.7)" v-model="surveyNumber" class="mr-2 pa-0 ma-0" />
                  <v-btn color="primary" depressed @click="fetchSurveyByNumber">
                    <v-icon left>mdi-open-in-new</v-icon>
                      Open
                  </v-btn>
                </div>
              </form>
            </div>
            <div class="pt-4 text-center">
              <v-btn v-on:click="openPublicSurveys" color="primary" style="text-transform: unset">
                View public surveys
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LandingPageComponent",

  components: {},
  data: () => ({
    surveyNumber: "",
  }),

  async mounted() {
    await this.$store.dispatch("fetchPublicSurveys");
  },
  computed: {
    ...mapGetters({
      publicSurveys: "getPublicSurveys",

      isAuthenticated: "isLoggedIn",

      surveyCategories: "getAllSurveyCategories",

      privateSurveyId: "getPrivateSurveyId",
    }),
  },
  methods: {
    openPublicSurveys() {
      this.$router.push("/public-surveys");
    },
    logoutHandler() {
      localStorage.removeItem("apollo-token");

      this.$store.commit("REMOVE_LOGGED_USER");

      this.$router.push("/home");
    },
    async fetchSurveyByNumber() {
      if (this.surveyNumber != "") {
        await this.$store.dispatch("fetchSurveyByNumber", this.surveyNumber);
        if (this.privateSurveyId) {
          this.$router.push("/response/" + this.privateSurveyId);
        } else {
          this.$router.push("/");
        }
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-image: url('../assets/tz.jpeg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
</style>