<template>
  <div class="text-center">
    <v-overlay :value="this.$store.getters.getLoadingState" opacity="0.3" z-index="9999" >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
export default {
    name: 'Loader',
};
</script>
