import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import { cloneDeep, forOwn, isNil } from "lodash";

import authStore from "@/store/Auth-store/authStore";
import surveyStore from "@/store/survey-store/surveyStore";
import accessLevelStore from "@/store/accessLevelsStore/accessLevelStore.js";
import manageinstitutionsStore from "@/store/manageInstitutionsStore/manageInstitutionsStore.js";
import packageManagementStore from "@/store/packageManagementStore/packagemanagement.js";
import manageUsersStore from "@/store/manageUsers/manageUsersStore.js";
import landingPageStore from "@/store/landingPage/landingPageStore.js";
import systemManagementStore from "@/store/systemManagement/systemManagementStore.js";
import dashboardStore from "@/store/dashboards/dashboardsStore.js";

let ls = new SecureLS({
  encodingType: "aes",
  isCompression: false,
  encryptionSecret: 'eDodosov2',
});

Vue.use(Vuex);

export const initialStoreModules = {
  authStore,
  surveyStore,
  accessLevelStore,
  manageinstitutionsStore,
  packageManagementStore,
  manageUsersStore,
  landingPageStore,
  systemManagementStore,
  dashboardStore
}

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  getters:{
    getLoadingState: (state) => state.isLoading,
  },
  mutations: {
    setLoadingState: (state, data) => state.isLoading = data,
    RESET_STATE(state) {
      forOwn(initialStoreModules, (value, key) => {
        state[key] = cloneDeep(value.state);
      });
    },
    updateStoreItem: (state, item ) => {
      let {module, entity, uniqueKey, data, remove} = item;
      let index = state[module][entity].findIndex((el) => (el[uniqueKey] == data[uniqueKey]));
      if ( index != -1) {
        if (remove) {
          state[module][entity].splice(index, 1)
        } else {
          state[module][entity][index] = data
        }
      } else {
        state[module][entity].unshift(data)
      }
      state[module][entity] = [...state[module][entity]]
    },
    setStoreItem: (state, item) => {
      let {module, entity, data} =  item
      if (isNil(module) || module?.toLowerCase() == 'root'){
        state[entity] = data
      } else {
        state[module][entity] = data
      }
    },
  },

  modules: cloneDeep(initialStoreModules),
  plugins: [createPersistedState({
    key: 'EG',
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    }
  })]
});
