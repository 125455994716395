<template>
    <LandingPageComponent />
</template>

<script>
import LandingPageComponent from "@/components/LandingPageComponent.vue";
export default {
  name: "LandingPageView",
  components: {
    LandingPageComponent,
  },
};
</script>
