import { apolloClient } from "@/vue-apollo";
import Vue from "vue";
import createPackage from "@/graphql/packageManagement/createPackage.gql";
import getllPackages from "@/graphql/packageManagement/getAllPackages.gql";
import deletePackage from "@/graphql/packageManagement/deletePackage.gql"
import getPackage from "@/graphql/packageManagement/getPackage.gql";
import editPackage from "@/graphql/packageManagement/editPackage.gql";


const packagesManagementStore = {

  state: {

    allPackages:[],
    getPackagesNumber: 0,
    packageDetails: {}

  },

  mutations: {

        setAllPackages(state,data){
            
            state.allPackages = data;
        },

        setPackagesNumber(state, count){

          state.packagesNumber = count;

        },

        setPackage(state, packageDetails){

          state.packageDetails = packageDetails;

        }
  },

  getters: {

    getAllPackages(state){

      return state.allPackages;

    },

    getPackagesNumber(state){

      return state.packagesNumber;

    },

    getPackage(state){

      return  state.packageDetails;

    }


  },
  actions: {
    async createPackage(context, data) {
      await apolloClient
        .mutate({
          mutation: createPackage,
          variables: data,
        })
        .then((response) => {
          if (response.data.createPackage.status == 200) {
            Vue.swal({
              // toast: true,
              icon: "success",
              text: "package added successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Vue.swal({
              // toast: true,
              icon: "error",
              title: "Oops...",
              text: "Survey creation failed!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    },
    async fectAllPackages(context) {
        await apolloClient.query({
            query: getllPackages
        }).then((response)=>{

            context.commit("setAllPackages",response.data.allPackages);
            const count = response.data.allPackages.length

            context.commit("setPackagesNumber", count);

            // console.log("The number of packages is: ",count)

        })
    },

  async deletePackage(context, packageId){

    await apolloClient.mutate({

      mutation: deletePackage,

      variables: {

        packageUniqueId : packageId
        
      }
    })

    .then((response)=>{
      response
      // console.log("The data", response);

    })
  },

  async fetchPackage(context, packageId){

    await apolloClient.query({

      query: getPackage, 

      variables: {

        packageUniqueId: packageId

      }

    })

    .then((response)=>{

      context.commit("setPackage",response.data.getPackage );

      // console.log("The response is ", response.data.getPackage)

    })
  },

  async editPackage(context, data){

    await apolloClient.mutate({

      mutation: editPackage, 

      variables: data

    })

    .then((response)=>{

      if(response.data.editPackage.status == 200 ){

        Vue.swal({

          icon: "success",
          text: "package edited successfully!",
          showConfirmButton: false,
          timer: 1500,

        });

      }

      else{

        Vue.swal({

          icon: "error",
          title: "Oops...",
          text: "Error occured!",
          showConfirmButton: false,
          timer: 1500,

        });

      }

    })
  }

  },

};

export default packagesManagementStore;
